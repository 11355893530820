<!-- 会员列表 -->
<template>
	<div class="bg-white px-3" style="margin: -20px;margin-top: -1rem; margin-bottom: 0!important;">
		<!-- 头部 搜索 -->
		<button-search class="pt-3" placeholder="手机号/邮箱/会员名称" @search="searchEvent">
			<!-- 左边按钮 -->
			<template #left>
				<el-button type="success" size="mini" @click="openModel(false)">添加会员</el-button>
			</template>

			<!-- 高级搜索 表单 -->
			<template #form>
				<el-form inline ref="form" :model="search" label-width="80px">

					<!-- 搜索内容 -->
					<el-form-item label="搜索内容" class="mb-0">
						<el-input v-model="search.keyword" size="mini" placeholder="手机号/邮箱/会员名称"></el-input>
					</el-form-item>

					<!-- 会员等级 -->
					<el-form-item label="会员等级" class="mb-0">
						<el-select v-model="search.user_level_id" size="mini">
							<el-option v-for="(item,index) in user_level" :key="index" :label="item.name" :value="item.id"></el-option>
						</el-select>
					</el-form-item>

					<el-form-item>
						<el-button type="info" size="mini" @click="searchEvent">搜索</el-button>
						<el-button size="mini" @click="clearSearch">清空筛选条件</el-button>
					</el-form-item>
				</el-form>
			</template>
		</button-search>

		<!-- 会员列表 -->
		<el-table border class="mt-3" :data="tableData" style="width: 100%">

			<!-- 会员 -->
			<el-table-column width="280" label="会员">
				<template slot-scope="scope">
					<div class="media">
						<img class="mr-3 rounded-circle" :src="scope.row.avatar" style="width: 40px;height: 40px;" />
						<div class="media-body">
							<h6 class="mt-0 d-flex align-items-center">{{ scope.row.username }}</h6>
							用户id：{{ scope.row.id }}
						</div>
					</div>
				</template>
			</el-table-column>

			<!-- 会员等级 -->
			<el-table-column align="center" label="硬币">
				<template slot-scope="scope">
					{{ scope.row.jf}}
				</template>
			</el-table-column>

			<!-- 登录注册 -->
			<el-table-column align="center" width="250" label="登录注册">
				<template slot-scope="scope">
					<div>注册时间 : {{ scope.row.create_time }}</div>
					<div v-if="scope.row.last_login_time">最后登录 : {{ scope.row.last_login_time }}</div>
				</template>
			</el-table-column>

			<!-- 状态 -->
			<el-table-column prop="status" align="center" label="状态">
				<template slot-scope="scope">
					<el-button :type="scope.row.status ? 'success' : 'danger'" size="mini" @click="changeStatus(scope.row)" plain>
						{{ scope.row.status ? '启用' : '禁用' }}
					</el-button>
				</template>
			</el-table-column>

			<!-- 操作 -->
			<el-table-column align="center" label="操作" width="150">
				<template slot-scope="scope">
					<el-button type="text" size="mini" @click="openModel(scope)">修改</el-button>
					<el-button type="text" size="mini" @click="deleteItem(scope.row)">删除</el-button>
				</template>
			</el-table-column>
		</el-table>
		<!-- 底部内容 -->
		<div style="height: 60px;"></div>
		<el-footer class="border-top d-flex align-items-center px-0 position-fixed bg-white" style="bottom: 0; left: 200px; right: 0;z-index: 100;">
			<!-- 底部分页 -->
			<div style="flex: 1;" class="px-2">
				<el-pagination @size-change="handleSizeChange" @current-change="handleCurrentChange" :current-page="page.current"
				 :page-sizes="page.sizes" :page-size="page.size" layout="total, sizes, prev, pager, next, jumper" :total="page.total">
				</el-pagination>
			</div>
		</el-footer>
		<!-- 新增/修改模态框 -->
		<el-dialog title="添加会员" :visible.sync="createModel" top="5vh">
			<!-- 表单内容 -->
			<el-form ref="form" :model="form" label-width="80px">

				<!-- 用户名 -->
				<el-form-item label="用户名" prop="username">
					<el-input v-model="form.username" placeholder="用户名" size="mini" style="width: 25%;"></el-input>
				</el-form-item>

				<!-- 密码 -->
				<el-form-item label="密码" prop="password">
					<el-input v-model="form.password" placeholder="密码" size="mini" style="width: 25%;"></el-input>
				</el-form-item>

				<!-- 昵称 -->
				<el-form-item label="昵称" prop="nickname">
					<el-input v-model="form.nickname" placeholder="昵称" size="mini" style="width: 25%;"></el-input>
				</el-form-item>

				<!-- 头像 -->
				<el-form-item label="头像">
					<div>
						<span v-if="!form.avatar" class="btn btn-light border mr-2" @click="chooseImage">
							<i class="el-icon-plus"></i>
						</span>
						<img v-else :src="form.avatar" class="rounded" style="width: 45px;height: 45px;cursor: pointer;" @click="chooseImage" />
					</div>
				</el-form-item>

				<el-form-item label="硬币" prop="phone">
					<el-input v-model="form.jf" placeholder="硬币" size="mini" style="width: 25%;"></el-input>
				</el-form-item>
				
				<!-- 状态 -->
				<el-form-item label="状态">
					<el-radio-group v-model="form.status" size="mini">
						<el-radio :label="1" border>启用</el-radio>
						<el-radio :label="0" border>禁用</el-radio>
					</el-radio-group>
				</el-form-item>
			</el-form>

			<div slot="footer" class="dialog-footer">
				<el-button @click="createModel = false">取 消</el-button>
				<el-button type="primary" @click="submit">确 定</el-button>
				
			</div>
		</el-dialog>

	</div>
</template>

<script>
	import buttonSearch from '@/components/common/button-search.vue'; // 引入 按钮、搜索、高级搜索组件
	import common from '@/common/mixins/common.js'; // 引入 全局共公样式
	export default {
		inject: ['app','layout'],
		mixins: [common], // 引入mixins里的common.js
		// 引入注册
		components: {
			buttonSearch
		},
		data() {
			return {
				preUrl:"user", //api
				tableData: [],
				createModel:false,
				editIndex:-1,
				search:{
					keyword:"",
					user_level_id:"",
				},
				
				form:{
					username:"",
					password:"",
					nickname:"",
					avatar:"",
					user_level_id:1,
					phone:"",
					email:"",
					status:1,
					jf:0
				},
				user_level:[]
			}
		},
		// 生命周期监听页面
		methods: {
			// 获取列表 可修改
			getListResult(e) {
				console.log(e)
				this.tableData = e.list
				this.user_level = e.user_level
			},
			
			// 打开模态框
			openModel(e = false) {
				// 增加新规格
				if (!e) {
					// 初始化表单
					this.form = {
						username:"", //用户名
						password:"", //密码
						nickname:"", //昵称
						avatar:"", //头像
						phone:"", //手机号
						email:"", //邮箱
						status:1, //是否启用
						jf:0
					}
					this.editIndex = -1
				} else {
					// 修改
					this.form = {
						username:e.row.username,
						password:e.row.password,
						nickname:e.row.nickname,
						avatar:e.row.avatar,
						phone:e.row.phone,
						email:e.row.email,
						status:e.row.status,
						jf:e.row.jf
					}
					this.editIndex = e.$index
				}
				// 打开模态框
				this.createModel = true
			},
			// 添加规格
			submit() {
				let id = 0
				if (this.editIndex !== -1) {
					id = this.tableData[this.editIndex].id
				}
				this.addOrEdit(this.form,id) //调用common.js里的添加方法
				// 关闭模态框
				this.createModel = false
			},
			// 清空筛选条件
			clearSearch() {
				this.search = {
					keyword: "",
					user_level_id: "",
				}
			},
			// 获取请求列表分页url
			getListUrl() {
				return `/admin/${this.preUrl}/${this.page.current}?limit=${this.page.size}&keyword=${this.search.keyword}&user_level_id=${this.search.user_level_id}`;
			},
			// 搜索事件
			searchEvent(e = false) {
				// 简单搜索
				if (typeof e === 'string') {
					this.search.keyword = e
					this.getList()
					return
				}
				// 高级搜索
				this.getList()
			},
			// 选择头像
			chooseImage() {
				this.app.chooseImage((res) => {
					this.form.avatar = res[0].url
				}, 1)
			}
		}
	}
</script>

<style>
</style>
